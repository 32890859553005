<script setup lang="ts">
import type { OrganismsPopoverProps } from './OrganismsPopover.props'
const { breakpoint } = useProjectBreakpoint()

const props = defineProps<OrganismsPopoverProps>()

const { isDialogOpen, closeDialog } = useDialog(props.id)

const isMobile = breakpoint.isSmallerOrEqual('md')
// SCROLL-LOCK BODY
const body = ref<HTMLElement>()

onMounted(() => {
  body.value = document?.body
})

const isLocked = useScrollLock(body)
watch(isDialogOpen, (newVal, oldVal) => {
  if (props.isScrollLocked && isMobile) return
  if (newVal !== oldVal) isLocked.value = !isLocked.value
})

const closePopover = () => {
  closeDialog(props.id)
}

onKeyStroke(
  'Escape',
  e => {
    e.preventDefault()
    closePopover()
  },
  { dedupe: false }
)

const transition = computed(() => {
  const defaultTransition = {
    enter: 'duration-700 ease-out',
    enterFrom: 'translate-x-[-100%]',
    enterTo: 'translate-x-0',
    leave: 'duration-500 ease-in no-scrollbar',
    leaveFrom: 'translate-x-0',
    leaveTo: 'translate-x-[-100%]',
  }

  if (props.hasResponsiveTransition) {
    return {
      ...defaultTransition,
      enterFrom: 'translate-x-[-100%] lg:translate-x-0 lg:translate-y-[-100%]',
      enterTo: 'translate-x-0 lg:translate-x-0 lg:translate-y-0',
      leaveFrom: 'translate-x-0 lg:translate-x-0 lg:translate-y-0',
      leaveTo: 'translate-x-[-100%] lg:translate-x-0 lg:translate-y-[-100%]',
    }
  }

  return defaultTransition
})
</script>

<template>
  <HeadlessTransitionRoot appear :show="isDialogOpen" as="template">
    <HeadlessPopover>
      <!-- Overlay -->
      <HeadlessTransitionChild
        as="div"
        enter="transition-opacity ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-in duration-700"
        leave-from="opacity-100"
        leave-to="opacity-0"
        class="bg-primitives-white/80 fixed inset-0 z-50 backdrop-blur-md"
        :class="[
          isFullHeightMobile
            ? 'h-full'
            : 'h-[calc(100%-var(--navbar-height))] lg:h-full',
        ]"
      />
      <!-- Popover -->
      <div
        class="fixed inset-0 z-50 overflow-y-hidden"
        :class="[
          isFullHeightMobile
            ? 'h-full'
            : 'h-[calc(100%-var(--navbar-height))] lg:h-full',
          {
            'z-[99]': overlayLevel,
          },
        ]"
        @wheel.stop="''"
      >
        <HeadlessTransitionChild
          as="template"
          :enter="transition.enter"
          :enter-from="transition.enterFrom"
          :enter-to="transition.enterTo"
          :leave="transition.leave"
          :leave-from="transition.leaveFrom"
          :leave-to="transition.leaveTo"
        >
          <HeadlessPopoverPanel
            class="bg-neutral-white max-h-screen w-full overflow-y-auto"
          >
            <button
              v-if="!hideCloseButton"
              class="absolute right-4 top-8 z-50 md:right-6 lg:right-12 xl:right-16"
              :aria-label="`${$ts('close')} ${description ?? ''}`"
              @click="() => closePopover()"
            >
              <DefaultIconsClose
                aria-hidden="true"
                class="text-text-primary h-8 w-8"
              />
            </button>
            <slot name="body" />
          </HeadlessPopoverPanel>
        </HeadlessTransitionChild>
      </div>
    </HeadlessPopover>
  </HeadlessTransitionRoot>
</template>

<style scoped lang="scss">
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
